<template>
  <div  style="padding: 10px !important;">

    <v-app-bar elevation="4" height="78" fixed rounded bottom style="align-items:center;width:94%;right:3%;left:inherit;top:9px;">
      <div  class="search-bar" >
        <v-row>
          <v-col cols="12" class="text-center  align-center " >
            <span style="font-size: 16px;color:#cc2392" v-if="mablagh != 0"><b> مبلغ این فرم = {{ mablagh }}</b>  </span><br>
            <span style="font-size: 16px;color:#2b2cc4" v-if="serial != 0"><b>سریال فرم فعلی شما = {{ serial }}</b>   </span><br>

            <span style="font-size: 16px;color:#3d1300" v-if="code_n != 0"><b> کدنمایندگی شما = {{ code_n }}</b>  </span>

          </v-col>

        </v-row>
      </div>
    </v-app-bar>



    <v-divider style="margin-top:84px;"></v-divider>
    <v-card><v-switch
        v-model="ofoghi"
        flat
        color="red"
        label="نمایش به صورت افقی"
    ></v-switch></v-card>
    <v-divider style="margin-top:10px;"></v-divider>
    <vcl-Twitch  v-if="!pageloading" ></vcl-Twitch>
    <vcl-Table  v-if="!pageloading" ></vcl-Table>




      <div v-if="ofoghi && pageloading">

        <table class="styled-table" >
          <thead style="padding-right: 0">
          <tr >
            <th style="padding-right: 0">میزبان</th>
            <th style="padding-right: 0">برد</th>
            <th style="padding-right: 0">مساوی</th>
            <th style="padding-right: 0">باخت</th>
            <th style="padding-right: 0">میهمان</th>
          </tr>
          </thead>
          <tbody v-for ="(item, index) in teamizban">

          <tr >
            <td style="padding-right: 1px"><span style="font-size: 13px"> {{ index+1 }})-<b>{{ item }}</b> </span></td>
            <td style="padding-right: 0">
              <v-checkbox @click="addFind"
                            v-model="tikha[(3*index)]"
                            style="width:65%;  margin-top: 0 !important;  padding-top: 0 !important;"
                            color="red"
                            value="l"
                            hide-details
            ></v-checkbox>


            </td>
            <td style="padding-right: 0"><v-checkbox @click="addFind"
                            v-model="tikha[(3*index)+1]"
                            style="width:65%;  margin-top: 0 !important;  padding-top: 0 !important;"
                            color="red"
                            value="l"
                            hide-details
            ></v-checkbox></td>
            <td style="padding-right: 0"><v-checkbox @click="addFind"
                            v-model="tikha[(3*index)+2]"
                            style="width:65%;  margin-top: 0 !important;  padding-top: 0 !important;"
                            color="red"
                            value="l"
                            hide-details
            ></v-checkbox></td>
            <td style="padding-right: 0"><span style="font-size: 13px"><b>{{ teammihman[index]}}</b> </span></td>

          </tr>
          <tr>
            <td colspan="5"><span style="font-size: 13px"><b>({{ saat[index] }}) ، ساعت شروع بازی {{ date[index] }}</b>  </span></td>
          </tr>

          </tbody>
        </table>


      </div>

    <v-card v-else  v-for ="(item, index) in teamizban"
             class="align-center "
             color="#cffced"
             style="width:100% !important;margin-bottom: 10px;border: 1px solid #619d03;"
    >

      <div style="background-color: hsla(0, 0%, 0%, 0);" >
        <v-card
          class="flex align-center"
          tile
      >
        <span style="font-size: 13px;"> {{ index+1 }})-<b>{{ item }}</b> (ساعت شروع بازی {{ date[index] }})  </span>
      </v-card>
        <v-card
            class="flex align-center "
            tile
            style="background-color: hsla(0, 0%, 0%, 0);"
        >
          <v-checkbox @click="addFind"
                      v-model="tikha[(3*index)]"
                      label="برد میزبان"
                      style="  margin-top: 0 !important;  padding-top: 0 !important;"
                      color="red"
                      value="l"
                      hide-details
          ></v-checkbox>

        </v-card>
        <v-card
            class="   align-center "
            tile
            style="background-color: hsla(0, 0%, 0%, 0);"
        >
          <v-checkbox @click="addFind"
                      v-model="tikha[(3*index)+1]"
                      label="مساوی"
                      style="  margin-top: 0 !important;  padding-top: 0 !important;"
                      color="red"
                      value="l"
                      hide-details
          ></v-checkbox>
        </v-card>
        <v-card
            class=" align-center  "
            tile
            style="background-color: hsla(0, 0%, 0%, 0);"
        >
          <v-checkbox @click="addFind"
                      v-model="tikha[(3*index)+2]"
                      label="برد میهمان"
                      style="  margin-top: 0 !important;  padding-top: 0 !important;"
                      color="red"
                      value="l"
                      hide-details
          ></v-checkbox>

        </v-card>
        <v-card
            class=" align-center "
            tile
        >
          <span style="font-size: 13px"><b>{{ teammihman[index]}}</b> <p  style=" color : #8f08ea">({{ saat[index] }})</p> </span>
        </v-card></div>


    </v-card>

    <v-divider style="margin-top:10px;"></v-divider>
    <v-row>
      <v-col cols="12"  >
        <v-btn @click="sabtform" v-if="pageloading" :loading="loading" depressed color="#1bffa5" height="50" style="width: 100%"> <b>ثبت فرم به صورت عادی</b></v-btn><br>
        <v-divider style="margin-top:10px;"></v-divider>
        <!--<v-row justify="center" >
        <v-col cols="7"  >
        <v-btn @click="sabtformsherakati" v-if="pageloading" :loading="loading" depressed color="#ea43af" height="50" style="width: 100%"> <b>ثبت فرم به صورت شراکتی</b></v-btn>
        </v-col>
        <v-col cols="5"  >
            <v-text-field label="تعداد سهمی درخواستی"  ></v-text-field>
        </v-col>
        </v-row>

        <v-btn @click="sherakatihekp=true" v-if="pageloading" :loading="loading" depressed color="#a343af" height="50" style="width: 100%"> <b>راهنمای ثبت فرم به صورت شراکتی</b></v-btn><br>
        <v-divider style="margin-top:10px;"></v-divider><br>
        <v-btn @click="sherakatihekp=true" v-if="pageloading" :loading="loading" depressed color="#bbe3af" height="50" style="width: 100%"> <b>نمایش فرمهای شراکتی ثبت شده توسط دیگران</b></v-btn><br>
        <v-divider style="margin-top:10px;"></v-divider>-->
        <v-btn @click="$router.push({path:'/modiriatform/'+noe});" v-if="pageloading" :loading="loading" depressed color="#5dffff" height="50" style="width: 100%"><b> مدیریت فرمها</b></v-btn>
      </v-col>
      <v-row justify="center" mt-40px >
        <v-dialog v-model="sherakatihekp" fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card>
            <v-toolbar dark color="primary">
              <v-btn icon dark @click="sherakatihekp = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>راهنمای ثبت فرم شراکتی</v-toolbar-title>
            </v-toolbar>

              <v-col cols="12" style="text-align: justify">
                <p>در این نوع از شرکت در مسابقات یک کاربر میتواند یک فرم ثبت نماید و با توجه به اینکه ممکن است مبلغ فرم را نتواند پرداخت کند میتواند به کمک سایرین فرم خود را پرداخت نماید.</p>
              </v-col>
              <v-col cols="12" style="text-align: justify">
              <p>هر فرم به ده سهم تقسیم میشود فردی که فرمی را ثبت میکند میتواند از ۱ تا ۹ سهم از ان فرم را در اختیار بگیرد. هر فردی که ان فرم را انتخاب کند و سهمی از آن فرم دریافت کند اگر ان فرم در لیست جوایز قرار بگیرد با توجه به سهمی که در هنگام ثبت فرم دریافت کرده بود جایزه به حسابش واریز میشود.</p>
              </v-col>
              <v-col cols="12" style="text-align: justify">
              <p>اگر فرمی که توسط نفر ثبت کننده تمام سهم هایش تا انتهای مهلت مسابقات گرفته نشود آن فرم از لیست مسابقات حذف و مبلغ به افرادی که سهمی پرداخت کرده بودند برگشت داده میشود.</p>
              </v-col>
              <v-col>
                <v-btn color="success" @click="sherakatihekp=false" :loading="loading" style="width: 100%">بستن</v-btn>
              </v-col>

          </v-card>
        </v-dialog>
      </v-row>
    </v-row>
    <v-divider style="margin-top:80px;"></v-divider>
    <app-footer></app-footer>
  </div>
</template>

<script>
// @ is an alias to /src


import axios from "axios";
import {mapGetters} from 'vuex'
import appHeader from '../views/_header'
import appFooter from '../views/_footer'
import { VclFacebook, VclCode,VclTwitch,VclTable } from 'vue-content-loading';
import { EnhancedCheck } from 'vue-enhanced-check'

export default {
  name: 'games',
  components: {
    appHeader,
    appFooter,
    VclFacebook,
    VclCode,
    VclTable,
    VclTwitch,
  },
  data () {
    return {
      show: true,
      ex4: ['red', 'indigo', 'orange', 'primary', 'secondary', 'success', 'info', 'warning', 'error', 'red darken-3', 'indigo darken-3', 'orange darken-3'],
      games:[],
      url:'https://sharjtosharj.com/soccerp/',
      pageloading: false,
      loading: false,
      teamizban:[],
      teammihman:[],
      saat:[],
      date:[],
      tikha: Array(63).fill('m'),
      mabcheck: Array(63).fill(0),
      tiks:'',
      serial:0,
      mablagh:0,
      code_n:0,
      noe:0,
      ofoghi:true,
      sherakatihekp:false
    }
  },
  mounted() {
    let self = this;
    self.getteam();
    self.addFind();

  },
  computed:{
    ...mapGetters(['baseUrl',"auth"]),

  },
  methods: {
    randomColor() {
      const r = () => Math.floor(400 * Math.random());

      return `rgb(${r()}, ${r()}, ${r()})`;
    },
    addFind: function () {

      let countt = 0;
      this.serial = 0;
      for (let i = 0; i < this.tikha.length; i++) {
        if (this.tikha[i] == null || this.tikha[i] == "m") {
          this.tikha[i] = 'm'
          this.mabcheck[i] = 0;
        } else {
          this.mabcheck[i] = 1;
        }
      }

      countt = this.checkemptytakoja(21);
      //console.log("countt="+countt);


      this.tiks = '';
      for (let i = 0; i < this.tikha.length; i++) {
        if (this.tikha[i] == 'm')
          this.tiks = this.tiks + 'm,';
        else
          this.tiks = this.tiks + 'l,';
      }
      // if (this.checkprice(12,10) >= 1)
      {
        if (this.$route.params.id == 4) {
          this.mablagh = this.checkprice(14, 5);
        } else if (this.$route.params.id == 9) {
          this.mablagh = this.checkprice(13, 5);
        } else if (this.$route.params.id == 8) {
          this.mablagh = this.checkprice(15, 2);
        } else if (this.$route.params.id == 5) {
          this.mablagh = this.checkprice(15, 2);
        } else if (this.$route.params.id == 3 || this.$route.params.id == 16) {
          this.mablagh = this.checkprice(16, 1);
        } else if (this.$route.params.id == 7) {
          this.mablagh = this.checkprice(17, 1);
        } else if (this.$route.params.id == 30) {
          if(this.checkprice(20, 0.05) < 1) this.mablagh =1;
          else this.mablagh = Math.round(this.checkprice(20, 0.05));
          //  if(countt <= 12)
          // {
         // this.mablagh = Math.round(this.checkprice20taee(countt, 1.2));
          // }
          // else
          // {
          //   this.mablagh=this.checkprice20taee(countt,1.2)
          // }

          // if( this.mablagh < 5000)
          //   this.mablagh=5000;

        }

      }
      // else
      //    this.mablagh=5000;
      // console.log(this.mablagh);
    },
    checkprice20taee: function (count, payeh) {
      let price = 1;
      let i = 0;
      let b = 1;
      let tick = 0;
      while (i < (count * 3) - 2) {
        if (this.mabcheck[i] + this.mabcheck[i + 1] + this.mabcheck[i + 2] > 0) {
          b = b * (this.mabcheck[i] + this.mabcheck[i + 1] + this.mabcheck[i + 2]);
          tick = (this.mabcheck[i] + this.mabcheck[i + 1] + this.mabcheck[i + 2]);
          price = price + (tick * payeh * b);
          //console.log('price:' + price + ',' + 'tick:' + tick + ',b:' + b + ',mablagh:' + this.mablagh);
        }
        i = i + 3;
      }
      //if (price*payeh < 5000*payeh ) price=5000;
      //console.log("(price*payeh=)"+price*payeh+"(count=)"+count+"(price=)"+price+"(payeh=)"+payeh);
      //console.log("(tick=)"+this.mabcheck);
      return price;

    },
    checkprice: function (count, payeh) {
      let price = 1;
      let i = 0;

      while (i < (count * 3) - 2) {
        if (this.mabcheck[i] + this.mabcheck[i + 1] + this.mabcheck[i + 2] > 0) {
          price = price * (this.mabcheck[i] + this.mabcheck[i + 1] + this.mabcheck[i + 2]);
        }
        i = i + 3;
      }
      //if (price*payeh < 5000*payeh ) price=5000;
      //console.log("(price*payeh=)"+price*payeh+"(count=)"+count+"(price=)"+price+"(payeh=)"+payeh);
      //console.log("(tick=)"+this.mabcheck);
      return price * payeh;
    },
    checkempty: function (tedad) {

      let i = 0;
      let emptys = false;
      while (i < (tedad * 3) - 2) {
        if (this.mabcheck[i] + this.mabcheck[i + 1] + this.mabcheck[i + 2] == 0) {
          emptys = true;
          break;
        }
        i = i + 3;
      }
      return emptys;
    },
    checkempty20: function (tedad) {

      let i = 0;
      let i1 = 0;
      let emptys = 0;
      let emptys1 = 0;
      while (i < (tedad * 3) - 2) {
        if (this.mabcheck[i] + this.mabcheck[i + 1] + this.mabcheck[i + 2] == 0) {
          emptys = i;
          break;
        }
        i = i + 3;
      }
      i1 = i;
      while (i1 < (20 * 3) - 2) {
        if (this.mabcheck[i1] + this.mabcheck[i1 + 1] + this.mabcheck[i1 + 2] > 0) {
          emptys1 = i1;
          break;
        }
        i1 = i1 + 3;
      }
      //console.log("(emptys=)"+emptys+","+"(emptys1=)"+emptys1);
      if (emptys1 == 0)
        return false;
      else
        return true;

    },
    checkemptytakoja: function (tedad) {

      let i = 0;
      let emptys = 1;
      while (i < (tedad * 3) - 2) {
        if (this.mabcheck[i] + this.mabcheck[i + 1] + this.mabcheck[i + 2] == 0) {
          if (i / 3 > 0) emptys = (i / 3);
          else emptys = 1;
          // console.log("i="+i+"=>"+this.mabcheck[i] +"," +this.mabcheck[i + 1]  +","+ this.mabcheck[i + 2]);
          break;
        }
        i = i + 3;
      }
      return emptys;
    },
    sabtformsherakati:function() {
    },
    sabtform15:function() {
      let self = this;
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      let formData = new FormData()
      self.loading = true;
      formData.append("tik", self.tiks);
      formData.append("code_shakhs", self.$store.getters.auth.data.user_id);
      formData.append("token", self.appToken);

      if(self.mablagh >=972) {
        axios.post(this.baseUrl + '/p15.aspx', formData, config)
            .then(function (r) {

              if (r.data.data.result == 1) {
                // console.log(r.data.data.result);
                self.serial = r.data.data.sh_barge;
                self.mablagh = r.data.data.mablagh;
                self.code_n = r.data.data.code_n;
                self.pageloading = true;

              } else {
                self.serial = 0;
                self.mablagh = 0;
                self.code_n = 0;
                self.helperToast({
                  show: true,
                  text: r.data.data.message,
                  color: 'red'
                });

              }
              self.loading = false;
            });
      }
      else
      {
        self.helperToast({
          show: true,
          text: "حداقل مبلغ ثبت فرم 972 تومان است",
          color: 'red'
        });
        self.loading = false;
      }
    },
    sabtform15m:function() {
      let self = this;
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      let formData = new FormData()
      self.loading = true;
      formData.append("tik", self.tiks);
      formData.append("code_shakhs", self.$store.getters.auth.data.user_id);
      formData.append("token", self.appToken);

      if(self.mablagh >=972) {
        axios.post(this.baseUrl + '/m15.aspx', formData, config)
            .then(function (r) {

              if (r.data.data.result == 1) {
                // console.log(r.data.data.result);
                self.serial = r.data.data.sh_barge;
                self.mablagh = r.data.data.mablagh;
                self.code_n = r.data.data.code_n;
                self.pageloading = true;

              } else {
                self.serial = 0;
                self.mablagh = 0;
                self.code_n = 0;
                self.helperToast({
                  show: true,
                  text: r.data.data.message,
                  color: 'red'
                });

              }
              self.loading = false;
            });
      }
      else
      {
        self.helperToast({
          show: true,
          text: "حداقل مبلغ ثبت فرم 972 تومان است",
          color: 'red'
        });
        self.loading = false;
      }
    },
    sabtform13:function() {
      let self = this;
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      let formData = new FormData()
      self.loading = true;
      formData.append("tik", self.tiks);
      formData.append("code_shakhs", self.$store.getters.auth.data.user_id);
      formData.append("token", self.appToken);

      if(self.mablagh >=972) {
        axios.post(this.baseUrl + '/r13.aspx', formData, config)
            .then(function (r) {

              if (r.data.data.result == 1) {
                // console.log(r.data.data.result);
                self.serial = r.data.data.sh_barge;
                self.mablagh = r.data.data.mablagh;
                self.code_n = r.data.data.code_n;
                self.pageloading = true;

              } else {
                self.serial = 0;
                self.mablagh = 0;
                self.code_n = 0;
                self.helperToast({
                  show: true,
                  text: r.data.data.message,
                  color: 'red'
                });

              }
              self.loading = false;
            });
      }
      else
      {
        self.helperToast({
          show: true,
          text: "حداقل مبلغ ثبت فرم ۵۰۰۰ تومان است",
          color: 'red'
        });
        self.loading = false;
      }

    },
    sabtform14m:function() {
      let self = this;
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      let formData = new FormData()
      self.loading = true;
      formData.append("tik", self.tiks);
      formData.append("code_shakhs", self.$store.getters.auth.data.user_id);
      formData.append("token", self.appToken);
      if(self.mablagh >=972) {
      axios.post(this.baseUrl+'/m14-2.aspx', formData, config)
          .then(function (r) {

            if(r.data.data.result==1){
              // console.log(r.data.data.result);
              self.serial=r.data.data.sh_barge;
              self.mablagh=r.data.data.mablagh;
              self.code_n=r.data.data.code_n;
              self.pageloading=true;

            }
            else
            {
              self.serial=0;
              self.mablagh=0;
              self.code_n=0;
              self.helperToast({
                show:true,
                text:r.data.data.message,
                color:'red'
              });

            }
            self.loading = false;
          });
      }
      else
      {
        self.helperToast({
          show: true,
          text: "حداقل مبلغ ثبت فرم 972 تومان است",
          color: 'red'
        });
        self.loading = false;
      }
    },
    sabtform16m:function() {
      let self = this;
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      let formData = new FormData()
      self.loading = true;
      formData.append("tik", self.tiks);
      formData.append("code_shakhs", self.$store.getters.auth.data.user_id);
      formData.append("token", self.appToken);
      if(self.mablagh >=972) {
        axios.post(this.baseUrl+'/m16.aspx', formData, config)
            .then(function (r) {

              if(r.data.data.result==1){
                // console.log(r.data.data.result);
                self.serial=r.data.data.sh_barge;
                self.mablagh=r.data.data.mablagh;
                self.code_n=r.data.data.code_n;
                self.pageloading=true;

              }
              else
              {
                self.serial=0;
                self.mablagh=0;
                self.code_n=0;
                self.helperToast({
                  show:true,
                  text:r.data.data.message,
                  color:'red'
                });

              }
              self.loading = false;
            });
      }
      else
      {
        self.helperToast({
          show: true,
          text: "حداقل مبلغ ثبت فرم 972 تومان است",
          color: 'red'
        });
        self.loading = false;
      }
    },
    sabtform16:function() {
      let self = this;
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      let formData = new FormData()
      self.loading = true;
      formData.append("tik", self.tiks);
      formData.append("code_shakhs", self.$store.getters.auth.data.user_id);
      formData.append("token", self.appToken);
      if(self.mablagh >=972) {
      axios.post(this.baseUrl+'/p16.aspx', formData, config)
          .then(function (r) {

            if(r.data.data.result==1){
              // console.log(r.data.data.result);
              self.serial=r.data.data.sh_barge;
              self.mablagh=r.data.data.mablagh;
              self.code_n=r.data.data.code_n;
              self.pageloading=true;

            }
            else
            {
              self.serial=0;
              self.mablagh=0;
              self.code_n=0;
              self.helperToast({
                show:true,
                text:r.data.data.message,
                color:'red'
              });

            }
            self.loading = false;
          });
      }
      else
      {
        self.helperToast({
          show: true,
          text: "حداقل مبلغ ثبت فرم 972 تومان است",
          color: 'red'
        });
        self.loading = false;
      }
    },
    sabtform17:function() {
      let self = this;
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      let formData = new FormData()
      self.loading = true;
      formData.append("tik", self.tiks);
      formData.append("code_shakhs", self.$store.getters.auth.data.user_id);
      formData.append("token", self.appToken);
      if(self.mablagh >=972) {
      axios.post(this.baseUrl+'/p17.aspx', formData, config)
          .then(function (r) {

            if(r.data.data.result==1){
              // console.log(r.data.data.result);
              self.serial=r.data.data.sh_barge;
              self.mablagh=r.data.data.mablagh;
              self.code_n=r.data.data.code_n;
              self.pageloading=true;

            }
            else
            {
              self.serial=0;
              self.mablagh=0;
              self.code_n=0;
              self.helperToast({
                show:true,
                text:r.data.data.message,
                color:'red'
              });

            }
            self.loading = false;
          });
      }
      else
      {
        self.helperToast({
          show: true,
          text: "حداقل مبلغ ثبت فرم 972 تومان است",
          color: 'red'
        });
        self.loading = false;
      }
    },
    sabtform20:function() {
      let self = this;
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      let formData = new FormData()
      self.loading = true;
      formData.append("tik", self.tiks);
      formData.append("code_shakhs", self.$store.getters.auth.data.user_id);
      formData.append("token", self.appToken);
      //console.log(self.tiks+"-"+self.appToken);
      axios.post(this.baseUrl+'/pm20.aspx', formData, config)
          .then(function (r) {
           // console.log(r.data.data.message);
            if(r.data.data.result==1){

              self.serial=r.data.data.sh_barge;
              self.mablagh=r.data.data.mablagh;
              self.code_n=r.data.data.code_n;
              self.pageloading=true;

            }
            else
            {
              self.serial=0;
              self.mablagh=0;
              self.code_n=0;
              self.helperToast({
                show:true,
                text:r.data.data.message,
                color:'red'
              });

            }
            self.loading = false;
          });
    },
    sabtform: function () {

      this.pageloading=false;

      if (this.noe==9)
      {
        if(!this.checkempty(13))
        {
          this.sabtform13();
        }
        else
        {
          this.helperToast({
            show:true,
            text:"حتما باید در هر ردیف یک گزینه انتخاب شود",
            color:'red'
          });

        }

       }
      else if (this.noe==4)
      {
        if(!this.checkempty(14))
        {
          this.sabtform14m();
        }
        else
        {
          this.helperToast({
            show:true,
            text:"حتما باید در هر ردیف یک گزینه انتخاب شود",
            color:'red'
          });

        }

      }
      else if (this.noe==8)
      {
        if(!this.checkempty(15))
        {
          this.sabtform15();
        }
        else
        {
          this.helperToast({
            show:true,
            text:"حتما باید در هر ردیف یک گزینه انتخاب شود",
            color:'red'
          });

        }

      }
      else if (this.noe==5)
      {
        if(!this.checkempty(15))
        {
          this.sabtform15m();
        }
        else
        {
          this.helperToast({
            show:true,
            text:"حتما باید در هر ردیف یک گزینه انتخاب شود",
            color:'red'
          });

        }

      }
      else if (this.noe==3)
      {
        if(!this.checkempty(16))
        {
          this.sabtform16();
        }
        else
        {
          this.helperToast({
            show:true,
            text:"حتما باید در هر ردیف یک گزینه انتخاب شود",
            color:'red'
          });

        }

      }
      else if (this.noe==16)
      {
        if(!this.checkempty(16))
        {
          this.sabtform16m();
        }
        else
        {
          this.helperToast({
            show:true,
            text:"حتما باید در هر ردیف یک گزینه انتخاب شود",
            color:'red'
          });

        }

      }
      else if (this.noe==7 )
      {
        if(!this.checkempty(17))
        {
          this.sabtform17();
        }
        else
        {
          this.helperToast({
            show:true,
            text:"حتما باید در هر ردیف یک گزینه انتخاب شود",
            color:'red'
          });

        }

      }
      else if (this.noe==30  )
      {
        if(!this.checkempty20(21))
        {
          this.sabtform20();
        }
        else
        {
          this.helperToast({
            show:true,
            text:"بازیها باید به ترتیب وارد شوند ",
            color:'red'
          });

        }
      }

      this.pageloading=true;

    },
    getteam(){
      let self = this;
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      let formData = new FormData()

      formData.append("token", self.appToken);
      formData.append("code_shakhs", self.$store.getters.auth.data.user_id);
      formData.append("noe", self.$route.params.id);
      //console.log(self.$store.getters.auth.data.user_id);

       axios.post(this.baseUrl+'/getteam.aspx', formData, config)
                .then(function (r) {
                 // console.log(r.data);
                  if(r.data.data.result=="1"){

                    self.teamizban=r.data.data.teammizban;
                    self.teammihman=r.data.data.teammihman;
                    self.saat=r.data.data.saat;
                    self.date=r.data.data.zamanbazi;
                    self.noe=r.data.data.noe;
                    self.pageloading=true;

                  }
                  else
                  {self.helperToast({
                    show:true,
                    text:r.data.data.message,
                    color:'red'
                  });

                  }
                  self.loading = false;
                });
    },


  },
  watch:{}
}
</script>

<style>
  .product-slider .v-btn--active{
    background: transparent
  }
  .product-slider .v-progress-linear {
    height: 2px  !important;
  }
  .search-bar{
    display: flex;
    align-items: center;
    border: solid 1px #dbd9d9;
    border-radius: 5px;
    height: 70px;
    width: 100%;
    padding-right:10px;
    background: #fff;
    font-size: 14px;
    transition: all 0.3s ease;
    margin-right: 5px;
  }
  .search-bar:focus{
    outline: none;
    border: solid 1px transparent;
    box-shadow: 0px 0px 4px -1px rgb(0 0 0 / 20%);
  }
  .product-property li{
    list-style: none;
    display: inline-block;
    width: 100%;
  }
  .product-property li > .product-param-list,
  .product-property li > .product-value-list{
      /* background: #f6f6f6; */
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 7px 10px 7px 7px;
      margin-bottom: 15px;
      display: inline-block;
      border-bottom: solid 1px #ddd;
  }
  .product-property li > .product-param-list{
    width: 35%;
    margin-left: 5%;
    font-size: 13px;
    color:black;
    vertical-align: top;
    border-bottom: none;
  }
  .product-property li > .product-value-list{
    width: 60%;
    font-size: 12px;
  }
  .product-property li:last-child .product-value-list{
    border-bottom: none;
  }
  .styled-table {
    border-collapse: collapse;
    font-size: 0.8em;
    min-width: 100%;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

  }
  .styled-table thead tr {
    background-color: #531fad;
    color: #ffffff;
    text-align: center;
  }
  .styled-table th,
  .styled-table td {
    padding: 12px 15px;
  }
  .styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
  }

  .styled-table tbody tr:nth-of-type(even) {
    background-color: #9fe7b0;
  }

  .styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #009879;
  }
  .styled-table tbody tr.active-row {
    font-weight: bold;
    color: #009879;
  }
</style>
