<template>
  <div>
    <v-app-bar elevation="4" height="65" fixed rounded bottom style="align-items:center;width:94%;right:3%;left:inherit;top:9px;">
      <v-icon  @click="drawer = !drawer" v-if="$store.getters.auth.loggedIn" large > mdi-dots-vertical-circle-outline </v-icon>
      <div id="search-bar" @click="goSearch"  class="search-barw" >
        <v-icon>mdi-magnify</v-icon>&nbsp;
        <span style="color:rgb(112 112 112)"> جستجو در</span> <span style="color:#f2166d;font-weight:bold">&nbsp; اخبار و اطلاعیه ها  </span>
      </div>
    </v-app-bar>

    <v-navigation-drawer  v-model="drawer" v-if="$store.getters.auth.loggedIn" fixed temporary :right="true"  >
      <v-list-item two-line>
        <v-list-item-avatar size="60" >
          <v-img  :src="$store.getters.auth.data.avatar"></v-img>
        </v-list-item-avatar>

        <v-list-item-content >

        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item v-for="item in items" :key="item.title" link>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <router-link :to=" item.link" class="rlink">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </router-link>

          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>

</template>

<script>
// @ is an alias to /src


export default {
  name: 'appHeader',
  data () {
    return {
      drawer:false,
      top:65,
      display:0,
      items: [
        { title: 'وبلاگ', icon: 'mdi-home-export-outline' , link:'/'},
        { title: 'ثبت مشکلات', icon: 'mdi-account-reactivate-outline', link:'/' },
        { title: 'نمایش مشکلات', icon: 'mdi-forum', link:'/' },
        //{ title: 'نمایش مشکلات ارجاع شده', icon: 'mdi-forum', link:'/showerja' },
      ],
    }
  },
  mounted(){
    //console.log(this.$store.getters.auth);
  },
  methods:{
    goSearch(){
      this.$router.push({path:'/search'})
    },
  },
  watch:{}
}
</script>

<style>
.v-system-bar{
  background: transparent;
}
.details p{
  font-size: 14px;
}
#bottom-nav{
  display: block;
}
.v-application--is-ltr .v-list-item__avatar:first-child{
  margin-left:16px;
  margin-right: inherit !important;
}
.v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child{
  margin-left:15px;
  margin-right: inherit !important;
}
.v-application--is-ltr .v-toolbar__content > .v-btn.v-btn--icon:first-child, .v-application--is-ltr .v-toolbar__extension > .v-btn.v-btn--icon:first-child {
  margin-left:inherit !important;
  margin-right: -12px;
}
.v-toolbar__content{
  padding: 4px 6px !important;
}
.search-barw{
  display: flex;
  align-items: center;
  border: solid 1px #dbd9d9;
  border-radius: 5px;
  height: 50px;
  width: 100%;
  padding-right:10px;
  background: #fff;
  font-size: 14px;
  transition: all 0.3s ease;
  margin-right: 5px;
}
.search-barw:focus{
  outline: none;
  border: solid 1px transparent;
  box-shadow: 0px 0px 4px -1px rgb(0 0 0 / 20%);
}
.rlink{
  text-decoration: none;
}
.rlink span{
  color: #263238
}
</style>
